import { tipoTask } from "../utils";

export async function returnIp(): Promise<string> {
	return fetch("https://geolocation-db.com/json/", {
		method: "GET",
		cache: "no-cache",
	})
		.then((res) => res.json())
		.then((data) => {
			return data.IPv4;
		});
}

export async function getDay(day: string) {
	const res = await fetch(
		(process.env.REACT_APP_FETCH || "") + "getDayExercises/?day=" + day
	);

	// console.log(
	// 	(process.env.REACT_APP_FETCH || "") + "getDayExercises/?day=" + day
	// );

	if (!res.ok) throw new Error("Data fetch failed!");

	return res.json();
}
export async function modifyExercise(
	id: string,
	reps: string,
	carico: string,
	recupero: string
) {
	await fetch(
		(process.env.REACT_APP_FETCH || "") +
			`modifyExercise/?id=${id}&reps=${reps}&carico=${carico}&recupero=${recupero}`
	);
}
export async function getTodayTasks(data: string) {
	const res = await fetch(
		(process.env.REACT_APP_FETCH || "") + "getTodayTasks/?data=" + data,
		{
			method: "GET",
			redirect: "follow",
			cache: "no-store",
		}
	);

	if (!res.ok) throw new Error("Data fetch failed!");

	return res.json();
}
export async function getTask(id: string) {
	const res = await fetch(
		(process.env.REACT_APP_FETCH || "") + "getTask/?id=" + id,
		{
			method: "GET",
			redirect: "follow",
			cache: "no-store",
		}
	);

	if (!res.ok) throw new Error("Data fetch failed!");

	return res.json();
}
export async function modifyTask(task: tipoTask) {
	let queryString = "?";
	let chiavi = Object.keys(task);

	chiavi.forEach((item) => {
		//@ts-ignore
		queryString += item + "=" + task[item] + "&";
	});

	queryString = queryString.slice(0, -1);

	const res = await fetch(
		(process.env.REACT_APP_FETCH || "") + "modifyTask/" + queryString,
		{
			method: "POST",
			redirect: "follow",
			cache: "no-store",
		}
	);

	if (!res.ok) throw new Error("Data fetch failed!");
}
export async function addTask(task: tipoTask) {
	let queryString = "?";
	let chiavi = Object.keys(task);

	chiavi.forEach((item) => {
		//@ts-ignore
		queryString += item + "=" + task[item] + "&";
	});

	queryString = queryString.slice(0, -1);

	const res = await fetch(
		(process.env.REACT_APP_FETCH || "") + "createTask/" + queryString,
		{
			method: "POST",
			redirect: "follow",
			cache: "no-store",
		}
	);

	if (!res.ok) throw new Error("Data fetch failed!");

	return res.json();
}
export async function deleteTask(id: number) {
	const res = await fetch(
		(process.env.REACT_APP_FETCH || "") + "deleteTask/?id=" + id,
		{
			method: "GET",
			redirect: "follow",
			cache: "no-store",
		}
	);

	if (!res.ok) throw new Error("Data fetch failed!");
}
export async function giorniConTask(anno: string, mese: string) {
	const res = await fetch(
		(process.env.REACT_APP_FETCH || "") +
			"giorniConTask/?anno=" +
			anno +
			"&mese=" +
			mese,
		{
			method: "POST",
			redirect: "follow",
			cache: "no-store",
		}
	);

	if (!res.ok) throw new Error("Data fetch failed!");

	return res.json();
}
