export const urlFetch: string = process.env.REACT_APP_FETCH as string;
export const topbarHeight: number = 10;
export const larghezzaSchermo = window.innerWidth;

export interface tipoEsercizio {
	id: string;
	nome: string;
	reps: string;
	carico: string;
	recupero: string;
	giorno: string;
	seq: string;
}

export type Nullable<T> = T | null;
export interface tipoTask {
	id: number;
	categoria: string;
	titolo: string;
	descrizione: string;
	stato: string;
	priorita: number;
	giorno: string;
	ora: Nullable<string>;
}

export interface arrayTask {
	num_rows: number;
	tasks: Array<tipoTask>;
}
export type giorni = {
	anno: string;
	mese: string;
	giorni: {
		d01: boolean;
		d02: boolean;
		d03: boolean;
		d04: boolean;
		d05: boolean;
		d06: boolean;
		d07: boolean;
		d08: boolean;
		d09: boolean;
		d10: boolean;
		d11: boolean;
		d12: boolean;
		d13: boolean;
		d14: boolean;
		d15: boolean;
		d16: boolean;
		d17: boolean;
		d18: boolean;
		d19: boolean;
		d20: boolean;
		d21: boolean;
		d22: boolean;
		d23: boolean;
		d24: boolean;
		d25: boolean;
		d26: boolean;
		d27: boolean;
		d28: boolean;
		d29: boolean;
		d30: boolean;
		d31: boolean;
	};
};

export function getFormattedDate(data: Date, tipo: string): string {
	if (tipo === "ita") {
		return (
			(data.getDate() < 10 ? "0" + data.getDate() : data.getDate()) +
			"/" +
			(data.getMonth() + 1 < 10
				? "0" + (data.getMonth() + 1)
				: data.getMonth() + 1) +
			"/" +
			data.getFullYear()
		);
	} else if (tipo === "eng") {
		return (
			data.getFullYear() +
			"-" +
			(data.getMonth() + 1 < 10
				? "0" + (data.getMonth() + 1)
				: data.getMonth() + 1) +
			"-" +
			(data.getDate() < 10 ? "0" + data.getDate() : data.getDate())
		);
	}

	return "-";
}
export function getMonthFromString(mon: string) {
	return new Date(Date.parse(mon + " 1, 2012")).getMonth() + 1;
}
