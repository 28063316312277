"use client";

import { useRef, useState } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import {
	Button,
	DateRange,
	DayPicker,
	DayProps,
	useDayRender,
} from "react-day-picker";

import { cn } from "./utils";
import { buttonVariants } from "./Button";
import { getFormattedDate, getMonthFromString, giorni } from "../../utils";
import { addMonths, isSameMonth } from "date-fns";

export type CalendarProps = React.ComponentProps<typeof DayPicker>;
type Props = CalendarProps & {
	giorni: giorni;
};

function Calendar({
	className,
	classNames,
	showOutsideDays = true,
	giorni,
	...props
}: Props) {
	return (
		<DayPicker
			showOutsideDays={showOutsideDays}
			className={cn("p-3", className)}
			classNames={{
				months: "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
				month: "space-y-4",
				caption: "flex justify-center pt-1 relative items-center",
				caption_label: "text-l font-medium",
				nav: "space-x-1 flex items-center",
				nav_button: cn(
					buttonVariants({ variant: "outline" }),
					"h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100"
				),
				nav_button_previous: "absolute left-1",
				nav_button_next: "absolute right-1",
				table: "w-full border-collapse space-y-1",
				head_row: "flex",
				head_cell:
					"text-muted-foreground rounded-md w-10 font-normal text-[0.9rem]",
				row: "flex w-full mt-2",
				cell: "h-10 w-10 text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
				day: cn(
					buttonVariants({ variant: "ghost" }),
					"h-10 w-10 p-0 font-normal aria-selected:opacity-100"
				),
				day_range_end: "day-range-end",
				day_selected:
					"bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground",
				day_today: "bg-accent text-accent-foreground",
				day_outside:
					"day-outside text-muted-foreground opacity-50 aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30",
				day_disabled: "text-muted-foreground opacity-50",
				day_range_middle:
					"aria-selected:bg-accent aria-selected:text-accent-foreground",
				day_hidden: "invisible",
				...classNames,
			}}
			components={{
				IconLeft: ({ ...props }) => <ChevronLeft className='h-7 w-7' />,
				IconRight: ({ ...props }) => <ChevronRight className='h-7 w-7' />,
				Day: ({ ...props }) => {
					const anno = getFormattedDate(props.displayMonth, "eng").split(
						"-"
					)[0];
					const mese = getFormattedDate(props.displayMonth, "eng").split(
						"-"
					)[1];
					const data = getFormattedDate(props.date, "eng").split("-")[2];
					const buttonRef = useRef(null);
					const dayRender = useDayRender(
						props.date,
						props.displayMonth,
						buttonRef
					);

					if (anno === giorni.anno && mese === giorni.mese) {
						return (
							<>
								<Button {...dayRender.buttonProps} ref={buttonRef}>
									<div className='flex flex-col justify-center items-center'>
										<p className='text-[15px]'>{data}</p>
										<p className='absolute bottom-0'>
											{
												//@ts-ignore
												giorni.giorni["d" + data] ? "\n·" : ""
											}
										</p>
									</div>
								</Button>
							</>
						);
					} else return <p>X</p>;
				},
			}}
			{...props}
		/>
	);
}
Calendar.displayName = "Calendar";

export { Calendar };
