import { useState } from "react";
import {
	Container,
	DivPagL,
	DivTotL,
	Navbar,
	Topbar,
} from "../../components/Components";
import css from "./organizzazione.module.css";
import { deleteTask, getTodayTasks } from "../../fetch/fetch";
import {
	arrayTask,
	getFormattedDate,
	larghezzaSchermo,
	tipoTask,
} from "../../utils";
import { useLoaderData, useNavigate } from "react-router-dom";
import { Button } from "../../components/shadcn/Button";

import imgDelete from "../../img/delete.png";
import imgDone from "../../img/done.png";
import imgInProgress from "../../img/inprogress.png";
import imgToDo from "../../img/todo.png";

function ordinaTask(filtri: string, taskOggi: Array<tipoTask>) {
	const prova = taskOggi;

	prova.sort((a: tipoTask, b: tipoTask) => {
		//@ts-ignore
		const x: string = a[filtri] as string;
		//@ts-ignore
		const y: string = b[filtri] as string;

		if (x !== y) {
			if (filtri === "priorita") return (y || "").localeCompare(x);
			if (filtri === "stato") {
				let xx = "";
				let yy = "";

				if (x === "td") xx = "a";
				else if (x === "ip") xx = "b";
				else if (x === "d") xx = "c";

				if (y === "td") yy = "a";
				else if (y === "ip") yy = "b";
				else if (y === "d") yy = "c";

				return (xx || "").localeCompare(yy);
			}
			return (x || "").localeCompare(y);
		} else {
			let xx = "";
			let yy = "";

			if (a["stato"] === "td") xx = "a";
			else if (a["stato"] === "ip") xx = "b";
			else if (a["stato"] === "d") xx = "c";

			if (b["stato"] === "td") yy = "a";
			else if (b["stato"] === "ip") yy = "b";
			else if (b["stato"] === "d") yy = "c";

			if (xx !== yy) return (xx || "").localeCompare(yy);
			else return b["priorita"] - a["priorita"];
		}
	});

	return prova;
}

export const TabellaS = ({
	taskOggi,
}: {
	taskOggi: {
		num_rows: number;
		tasks: Array<tipoTask>;
	};
}) => {
	const [filtri, setFiltri] = useState("ora");

	const navigate = useNavigate();

	function generaRighe() {
		const tasks = ordinaTask(filtri, taskOggi.tasks);

		return tasks.map((item, index) => {
			return (
				<tr
					key={index}
					className={css.elemento}
					onClick={() => navigate("/organizzazione/modifytask/" + item.id)}
				>
					<td>
						<div>{item.titolo}</div>
					</td>
					<td>
						<div>
							{item.ora === null || item.ora === "00:00:00"
								? "tutto il giorno"
								: item.ora}
						</div>
					</td>
					<td>
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							{item.stato === "td" ? (
								<img
									src={imgToDo}
									alt=''
									className={css.imgToDo}
									onClick={() => console.log("ciao")}
								/>
							) : item.stato === "ip" ? (
								<img src={imgInProgress} alt='' className={css.imgInProgress} />
							) : (
								<img src={imgDone} alt='' className={css.imgDone} />
							)}
						</div>
					</td>
				</tr>
			);
		});
	}

	return (
		<table className={css.tableS}>
			<thead>
				<tr style={{ borderBottom: "1px solid var(--coloreBordo)" }}>
					<th
						onClick={() => setFiltri("titolo")}
						className={filtri === "titolo" ? css.ordinato : ""}
					>
						Titolo
					</th>
					<th
						onClick={() => setFiltri("ora")}
						style={{ width: "20%" }}
						className={filtri === "ora" ? css.ordinato : ""}
					>
						Orario
					</th>
					<th
						onClick={() => setFiltri("stato")}
						style={{ width: "15%" }}
						className={filtri === "stato" ? css.ordinato : ""}
					>
						Stato
					</th>
				</tr>
			</thead>
			<tbody>{generaRighe()}</tbody>
		</table>
	);
};

export function TabellaL({
	taskOggi,
	modificaTaskOggi,
}: {
	taskOggi: {
		num_rows: number;
		tasks: Array<tipoTask>;
	};
	modificaTaskOggi: Function;
}) {
	const [filtri, setFiltri] = useState("ora");

	const navigate = useNavigate();

	console.log("rendering");

	function generaRighe() {
		const tasks = ordinaTask(filtri, taskOggi.tasks);

		return tasks.map((item, index) => {
			return (
				<tr key={index} className={css.elemento}>
					<td>
						<div
							style={{
								width: "100%",
								height: "100%",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<img
								src={imgDelete}
								alt=''
								style={{
									width: "30px",
									height: "auto",
									cursor: "pointer",
									filter:
										"invert(13%) sepia(91%) saturate(6260%) hue-rotate(2deg) brightness(97%) contrast(117%)",
								}}
								onClick={() => {
									deleteTask(item.id).then(() => {
										if (item.giorno === getFormattedDate(new Date(), "eng")) {
											modificaTaskOggi(item, "delete");
										}
									});
								}}
							/>
						</div>
					</td>
					<td onClick={() => navigate("/organizzazione/modifytask/" + item.id)}>
						<div>{item.categoria}</div>
					</td>
					<td onClick={() => navigate("/organizzazione/modifytask/" + item.id)}>
						<div>{item.titolo}</div>
					</td>
					<td onClick={() => navigate("/organizzazione/modifytask/" + item.id)}>
						<div>
							{item.ora === null || item.ora === "00:00:00"
								? "tutto il giorno"
								: item.ora}
						</div>
					</td>
					<td onClick={() => navigate("/organizzazione/modifytask/" + item.id)}>
						<div>{item.priorita}</div>
					</td>
					<td onClick={() => navigate("/organizzazione/modifytask/" + item.id)}>
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							{item.stato === "td" ? (
								<img src={imgToDo} alt='' className={css.imgToDo} />
							) : item.stato === "ip" ? (
								<img src={imgInProgress} alt='' className={css.imgInProgress} />
							) : (
								<img src={imgDone} alt='' className={css.imgDone} />
							)}
						</div>
					</td>
				</tr>
			);
		});
	}

	return (
		<table className={css.tableL}>
			<thead>
				<tr style={{ borderBottom: "1px solid var(--coloreBordo)" }}>
					<th style={{ width: "6%" }}>Elimina</th>
					<th
						onClick={() => setFiltri("categoria")}
						className={filtri === "categoria" ? css.ordinato : ""}
						style={{ width: "15%" }}
					>
						Categoria
					</th>
					<th
						onClick={() => setFiltri("titolo")}
						className={filtri === "titolo" ? css.ordinato : ""}
					>
						Titolo
					</th>
					<th
						onClick={() => setFiltri("ora")}
						className={filtri === "ora" ? css.ordinato : ""}
						style={{ width: "10%" }}
					>
						Orario
					</th>
					<th
						onClick={() => setFiltri("priorita")}
						className={filtri === "priorita" ? css.ordinato : ""}
						style={{ width: "10%" }}
					>
						Priorità
					</th>
					<th
						onClick={() => setFiltri("stato")}
						className={filtri === "stato" ? css.ordinato : ""}
						style={{ width: "10%" }}
					>
						Stato
					</th>
				</tr>
			</thead>
			<tbody>{generaRighe()}</tbody>
		</table>
	);
}

export function Organizzazione({
	taskOggiSalvato,
	modificaTaskOggi,
}: {
	taskOggiSalvato: arrayTask;
	modificaTaskOggi: Function;
}) {
	const dati: any = useLoaderData();
	const navigate = useNavigate();

	const [taskOggi, setTaskOggi] = useState({
		num_rows: -1,
		tasks: [],
	} as arrayTask);

	const oggi = getFormattedDate(new Date(), "eng");
	const data = oggi !== dati.data ? dati.data : oggi;

	if (taskOggi.num_rows === -1) {
		if (oggi === data) {
			setTaskOggi(taskOggiSalvato);
		} else {
			getTodayTasks(data).then((res) => {
				setTaskOggi(res);
			});
		}
	}

	return (
		<>
			{larghezzaSchermo < 1024 ? (
				<>
					<Topbar
						page={
							data.split("-")[2] +
							"/" +
							data.split("-")[1] +
							"/" +
							data.split("-")[0]
						}
						onclickfun={
							data !== oggi ? () => navigate("/calendario") : () => {}
						}
						tornaIndietro={data !== oggi ? true : false}
					/>
					<Container>
						<div
							style={{
								display: "flex",
								width: "100%",
								justifyContent: "center",
								marginTop: "10px",
								marginBottom: "60px",
							}}
						>
							<div className={css.divTabella}>
								{taskOggi.num_rows > 0 ? <TabellaS taskOggi={taskOggi} /> : ""}
							</div>
						</div>
						<div
							style={{
								width: "100%",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								position: "absolute",
								bottom: "calc(var(--navbarHeight) + 10px)",
							}}
						>
							<Button
								className='text-xl w-3/4'
								onClick={() => navigate("/organizzazione/createtask/" + data)}
								style={{ border: "1px solid var(--coloreBordo)" }}
								variant='outline'
							>
								Crea task
							</Button>
						</div>
					</Container>
					<Navbar page='organizzazione' />
				</>
			) : (
				<DivTotL>
					<Navbar page='organizzazione' />
					<DivPagL>
						<Topbar
							page={
								data.split("-")[2] +
								"/" +
								data.split("-")[1] +
								"/" +
								data.split("-")[0]
							}
							onclickfun={
								data !== oggi ? () => navigate("/calendario") : () => {}
							}
							tornaIndietro={data !== oggi ? true : false}
						/>
						<Container>
							<div
								style={{
									width: "100%",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									marginTop: "10px",
								}}
							>
								<Button
									className='text-xl w-1/4'
									onClick={() => navigate("/organizzazione/createtask/" + data)}
									variant='outline'
									style={{ border: "1px solid var(--coloreBordo)" }}
								>
									Crea task
								</Button>
							</div>
							<div
								style={{
									display: "flex",
									width: "100%",
									justifyContent: "center",
									marginBottom: "60px",
									marginTop: "20px",
								}}
							>
								<div className={css.divTabella}>
									{taskOggi.num_rows > 0 ? (
										<TabellaL
											taskOggi={taskOggi}
											modificaTaskOggi={modificaTaskOggi}
										/>
									) : (
										""
									)}
								</div>
							</div>
						</Container>
					</DivPagL>
				</DivTotL>
			)}
		</>
	);
}
