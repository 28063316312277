import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Homepage } from "./pages/Homepage";
import { Palestra } from "./pages/palestra/Palestra";
import { Organizzazione } from "./pages/organizzazione/Organizzazione";
import { Giorni } from "./pages/palestra/Giorni";
import { getTodayTasks, returnIp } from "./fetch/fetch";
import { useState } from "react";
import ModificaTask from "./pages/organizzazione/ModificaTask";
import Calendario from "./pages/calendario/Calendario";
import CreaTask from "./pages/calendario/CreaTask";
import { arrayTask, getFormattedDate, tipoTask } from "./utils";

function App() {
	const [resultIp, setResultIp] = useState({ res: false, ip: "CARICAMENTO" });
	const [taskOggi, setTaskOggi] = useState({
		num_rows: -1,
		tasks: [],
	} as arrayTask);

	const [richiestoIp, setRichiestoIp] = useState(false);
	const [richiestoOggi, setRichiestoOggi] = useState(false);

	//controllo ip
	if (resultIp.ip === "CARICAMENTO" && !richiestoIp) {
		setRichiestoIp(true);
		returnIp().then((ip) => {
			fetch((process.env.REACT_APP_FETCH || "") + "checkIp/?ip=" + ip, {
				method: "GET",
				redirect: "follow",
				cache: "no-store",
			})
				.then((res) => res.json())
				.then((res) => {
					setResultIp(res);
					if (res.res && !richiestoOggi) {
						setRichiestoOggi(true);
						getTodayTasks(getFormattedDate(new Date(), "eng")).then((res2) => {
							setTaskOggi(res2);
						});
					} else {
					}
				});
		});
	}

	function modificaTaskOggi(task: tipoTask, action: string) {
		if (action === "add") {
			if (taskOggi.num_rows > 0) {
				setTaskOggi({
					num_rows: taskOggi.num_rows + 1,
					tasks: [...taskOggi.tasks, task],
				});
			} else {
				setTaskOggi({
					num_rows: taskOggi.num_rows + 1,
					tasks: [task],
				});
			}
		} else if (action === "update") {
			let tmp = taskOggi.tasks;
			let tmp2: Array<tipoTask> = [];

			tmp.forEach((item, index) => {
				if (item.id === task.id) {
					tmp2.push(task);
				} else {
					tmp2.push(item);
				}
			});

			setTaskOggi({ num_rows: taskOggi.num_rows, tasks: tmp2 });
		} else if (action === "delete") {
			let tmp = taskOggi.tasks;
			let tmp2: Array<tipoTask> = [];

			tmp.forEach((item, index) => {
				if (item.id !== task.id) {
					tmp2.push(item);
				}
			});

			setTaskOggi({ num_rows: taskOggi.num_rows - 1, tasks: tmp2 });
		}
	}

	if (resultIp.res && taskOggi.num_rows > -1) {
		const router = createBrowserRouter([
			{
				path: "/",
				element: (
					<Homepage
						taskOggiSalvato={taskOggi}
						modificaTaskOggi={modificaTaskOggi}
					/>
				),
			},
			{
				path: "/palestra",
				element: <Palestra />,
			},
			{
				path: "/palestra/:day",
				element: <Giorni />,
				loader: ({ params }) => {
					return {
						day: params.day,
					};
				},
			},
			{
				path: "/organizzazione/:data",
				element: (
					<Organizzazione
						taskOggiSalvato={taskOggi}
						modificaTaskOggi={modificaTaskOggi}
					/>
				),
				loader: ({ params }) => {
					return {
						data: params.data,
					};
				},
			},
			{
				path: "/organizzazione/modifytask/:id",
				element: <ModificaTask modificaTaskOggi={modificaTaskOggi} />,
				loader: ({ params }) => {
					return {
						id: params.id,
					};
				},
			},
			{
				path: "/organizzazione/createtask/:data",
				element: <CreaTask modificaTaskOggi={modificaTaskOggi} />,
				loader: ({ params }) => {
					return {
						data: params.data,
					};
				},
			},
			{
				path: "/calendario",
				element: <Calendario />,
			},
			{
				path: "*",
				element: (
					<Homepage
						taskOggiSalvato={taskOggi}
						modificaTaskOggi={modificaTaskOggi}
					/>
				),
			},
		]);

		return <RouterProvider router={router} />;
	}

	return <p>{resultIp.ip}</p>;
}

export default App;
