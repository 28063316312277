import { useRef, useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { getFormattedDate, larghezzaSchermo, tipoTask } from "../../utils";
import { deleteTask, getTask, modifyTask } from "../../fetch/fetch";
import {
	Container,
	DivPagL,
	DivTotL,
	Navbar,
	Topbar,
} from "../../components/Components";
import css from "./modificatask.module.css";
import { Toaster } from "../../components/shadcn/Sonner";
import { toast } from "sonner";
import { Button } from "../../components/shadcn/Button";

import imgDone from "../../img/done.png";
import imgInProgress from "../../img/inprogress.png";
import imgToDo from "../../img/todo.png";

function Campi({
	taskOg,
	nuovaTask,
	setNuovaTask,
	modificaTaskOggi,
}: {
	taskOg: tipoTask;
	nuovaTask: tipoTask;
	setNuovaTask: Function;
	modificaTaskOggi: Function;
}) {
	const navigate = useNavigate();

	const refOra = useRef(null);
	const refGiorno = useRef(null);

	return (
		<>
			<div className={css.divCampo}>
				<p className={css.titoloSezione}>Titolo: *</p>
				<input
					type='text'
					defaultValue={taskOg.titolo}
					className={css.inputSezione}
					onChange={(e) => {
						setNuovaTask({ ...nuovaTask, titolo: e.target.value });
					}}
				/>
			</div>
			<div className={css.divCampo}>
				<p className={css.titoloSezione}>Descrizione:</p>
				<textarea
					className={css.inputSezione}
					style={{
						border: "1px solid var(--coloreBordo)",
						resize: "vertical",
					}}
					rows={10}
					onChange={(e) => {
						setNuovaTask({
							...nuovaTask,
							descrizione: e.target.value,
						});
					}}
					defaultValue={taskOg.descrizione}
				></textarea>
			</div>
			<div className={css.divCampo}>
				<p className={css.titoloSezione}>Categoria: *</p>
				<input
					type='text'
					defaultValue={taskOg.categoria}
					className={css.inputSezione}
					onChange={(e) => {
						setNuovaTask({ ...nuovaTask, categoria: e.target.value });
					}}
				/>
			</div>
			<div className={css.divCampo}>
				<p className={css.titoloSezione}>Data:</p>
				<div className='flex flex-col items-start justify-between w-full'>
					<div className='flex flex-row w-full'>
						<Button
							variant='outline'
							style={{ border: "1px solid var(--coloreBordo)" }}
							className='text-xl mr-[10px] w-40'
							onClick={() => {
								//@ts-ignore
								refGiorno.current.value = taskOg.giorno;
								setNuovaTask({ ...nuovaTask, giorno: taskOg.giorno });
							}}
						>
							{taskOg.giorno.split("-")[2] +
								"/" +
								taskOg.giorno.split("-")[1] +
								"/" +
								taskOg.giorno.split("-")[0]}
						</Button>
						<input
							type='date'
							defaultValue={taskOg.giorno}
							ref={refGiorno}
							className={css.inputSezione}
							onChange={(e) => {
								setNuovaTask({ ...nuovaTask, giorno: e.target.value });
							}}
							style={{ width: "100%" }}
						/>
					</div>
					<div className='flex flex-row w-full mt-[10px]'>
						<Button
							variant='outline'
							style={{ border: "1px solid var(--coloreBordo)" }}
							className='text-xl mr-[10px] w-40'
							onClick={() => {
								//@ts-ignore
								refOra.current.value = null;
								setNuovaTask({ ...nuovaTask, ora: null });
							}}
						>
							Tutto il giorno
						</Button>
						<input
							type='time'
							//@ts-ignore
							defaultValue={taskOg.ora}
							className={css.inputSezione}
							ref={refOra}
							onChange={(e) => {
								setNuovaTask({ ...nuovaTask, ora: e.target.value });
							}}
							style={{ width: "100%" }}
						/>
					</div>
				</div>
			</div>
			<div className={css.divCampo}>
				<p className={css.titoloSezione}>Priorità:</p>
				<div className={css.divTreBtn}>
					<div
						className={css.divBtn}
						style={
							nuovaTask.priorita.toString() === "1"
								? { borderColor: "#ff0" }
								: {}
						}
						onClick={() => setNuovaTask({ ...nuovaTask, priorita: 1 })}
					>
						<p>1</p>
					</div>
					<div
						className={css.divBtn}
						style={
							nuovaTask.priorita.toString() === "2"
								? { borderColor: "orange" }
								: {}
						}
						onClick={() => setNuovaTask({ ...nuovaTask, priorita: 2 })}
					>
						<p>2</p>
					</div>
					<div
						className={css.divBtn}
						style={
							nuovaTask.priorita.toString() === "3"
								? { borderColor: "#f00" }
								: {}
						}
						onClick={() => setNuovaTask({ ...nuovaTask, priorita: 3 })}
					>
						<p>3</p>
					</div>
				</div>
			</div>
			<div className={css.divCampo}>
				<p className={css.titoloSezione}>Stato:</p>
				<div className={css.divTreBtn}>
					<div
						className={css.divBtn}
						style={nuovaTask.stato === "td" ? { borderColor: "#f00" } : {}}
						onClick={() => setNuovaTask({ ...nuovaTask, stato: "td" })}
					>
						<img
							src={imgToDo}
							alt=''
							className={css.imgToDo}
							style={
								nuovaTask.stato === "td"
									? {
											filter:
												"invert(29%) sepia(91%) saturate(7444%) hue-rotate(357deg) brightness(94%) contrast(123%)",
									  }
									: {}
							}
						/>
					</div>
					<div
						className={css.divBtn}
						style={nuovaTask.stato === "ip" ? { borderColor: "orange" } : {}}
						onClick={() => setNuovaTask({ ...nuovaTask, stato: "ip" })}
					>
						<img
							src={imgInProgress}
							alt=''
							style={
								nuovaTask.stato === "ip"
									? {
											filter:
												"invert(74%) sepia(68%) saturate(397%) hue-rotate(357deg) brightness(93%) contrast(98%)",
									  }
									: {}
							}
							className={css.imgInProgress}
						/>
					</div>
					<div
						className={css.divBtn}
						style={nuovaTask.stato === "d" ? { borderColor: "#0f0" } : {}}
						onClick={() => setNuovaTask({ ...nuovaTask, stato: "d" })}
					>
						<img
							src={imgDone}
							alt=''
							style={
								nuovaTask.stato === "d"
									? {
											filter:
												"invert(50%) sepia(81%) saturate(2088%) hue-rotate(82deg) brightness(121%) contrast(120%)",
									  }
									: {}
							}
							className={css.imgDone}
						/>
					</div>
				</div>
			</div>
			<div
				className={css.divCampo}
				style={{ display: "flex", alignItems: "center" }}
			>
				<Button
					className='text-xl w-3/4 bg-red-600 text-white'
					onClick={() => {
						deleteTask(taskOg.id).then(() => {
							const giorno = nuovaTask.giorno;

							if (nuovaTask.giorno === getFormattedDate(new Date(), "eng")) {
								modificaTaskOggi(nuovaTask, "delete");
							}

							navigate("/organizzazione/" + giorno);
						});
					}}
				>
					Elimina task
				</Button>
			</div>
		</>
	);
}

function ModificaTask({ modificaTaskOggi }: { modificaTaskOggi: Function }) {
	const data: any = useLoaderData();
	const [taskOg, setTaskOg] = useState({} as tipoTask);
	const [nuovaTask, setNuovaTask] = useState({} as tipoTask);
	const navigate = useNavigate();

	if (Object.keys(taskOg).length === 0) {
		getTask(data.id).then((res: tipoTask) => {
			if (res !== null) {
				setTaskOg(res);
				setNuovaTask(res);
			}
		});
	}

	if (Object.keys(taskOg).length !== 0) {
		return (
			<>
				{larghezzaSchermo < 1024 ? (
					<>
						<Topbar
							page='Modifica task'
							tornaIndietro={true}
							onclickfun={() => {
								if (JSON.stringify(taskOg) !== JSON.stringify(nuovaTask)) {
									if (nuovaTask.categoria !== "" && nuovaTask.titolo !== "") {
										modifyTask(nuovaTask).then(() => {
											//impostare le task di oggi modificando quella modificata in questa pagina (usando l'id)

											if (
												nuovaTask.giorno === getFormattedDate(new Date(), "eng")
											) {
												modificaTaskOggi(nuovaTask, "update");
											}

											navigate("/organizzazione/" + nuovaTask.giorno);
										});
									} else toast.error("Titolo e categoria sono obbligatori!");
								} else navigate("/organizzazione/" + nuovaTask.giorno);
							}}
						/>
						<Container>
							<div className={css.divCampi}>
								<Campi
									modificaTaskOggi={modificaTaskOggi}
									nuovaTask={nuovaTask}
									setNuovaTask={setNuovaTask}
									taskOg={taskOg}
								/>
							</div>
						</Container>
						<Navbar page='organizzazione' />

						<Toaster richColors />
					</>
				) : (
					<DivTotL>
						<Navbar page='organizzazione' />
						<Toaster richColors />
						<DivPagL>
							<Topbar
								page='Modifica task'
								tornaIndietro={true}
								onclickfun={() => {
									if (JSON.stringify(taskOg) !== JSON.stringify(nuovaTask)) {
										if (nuovaTask.categoria !== "" && nuovaTask.titolo !== "") {
											modifyTask(nuovaTask).then(() => {
												//impostare le task di oggi modificando quella modificata in questa pagina (usando l'id)

												if (
													nuovaTask.giorno ===
													getFormattedDate(new Date(), "eng")
												) {
													modificaTaskOggi(nuovaTask, "update");
												}

												navigate("/organizzazione/" + nuovaTask.giorno);
											});
										} else toast.error("Titolo e categoria sono obbligatori!");
									} else navigate("/organizzazione/" + nuovaTask.giorno);
								}}
							/>
							<Container>
								<div className={css.divCampi}>
									<Campi
										modificaTaskOggi={modificaTaskOggi}
										nuovaTask={nuovaTask}
										setNuovaTask={setNuovaTask}
										taskOg={taskOg}
									/>
								</div>
							</Container>
						</DivPagL>
					</DivTotL>
				)}
			</>
		);
	} else return <>CARICAMENTO</>;
}

export default ModificaTask;
