import { useNavigate } from "react-router-dom";
import {
	Container,
	DivPagL,
	DivTotL,
	Navbar,
	Topbar,
} from "../components/Components";

import css from "./homepage.module.css";
import { useState } from "react";
import { arrayTask, getFormattedDate, larghezzaSchermo } from "../utils";
import { TabellaL, TabellaS } from "./organizzazione/Organizzazione";
import { Button } from "../components/shadcn/Button";

export function Homepage({
	taskOggiSalvato,
	modificaTaskOggi,
}: {
	taskOggiSalvato: arrayTask;
	modificaTaskOggi: Function;
}) {
	const navigate = useNavigate();
	// eslint-disable-next-line
	const [taskOggi, setTaskOggi] = useState(taskOggiSalvato);

	return (
		<>
			{larghezzaSchermo < 1024 ? (
				<>
					<Topbar page='home' onclickfun={() => {}} tornaIndietro={false} />
					<Container>
						<div className={css.divSezioni}>
							<Button
								onClick={() => navigate("/palestra")}
								className='h-[50px] border-stone-800 w-[60%]'
								variant='outline'
							>
								<p className={css.sezioniP}>Palestra</p>
							</Button>
							<Button
								onClick={() => {
									navigate(
										"/organizzazione/" + getFormattedDate(new Date(), "eng")
									);
								}}
								className='h-[50px] border-stone-800 w-[60%]'
								variant='outline'
							>
								<p className={css.sezioniP}>Organizzazione</p>
							</Button>
						</div>
						<div
							style={{
								display: "flex",
								width: "100%",
								justifyContent: "center",
							}}
						>
							<div className={css.divTasks}>
								<p className={css.titleTasks}>
									{taskOggi.num_rows > 0 ? "Da fare:" : ""}
								</p>
								{taskOggi.num_rows > 0 ? <TabellaS taskOggi={taskOggi} /> : ""}
							</div>
						</div>
					</Container>
					<Navbar page='home' />
				</>
			) : (
				<DivTotL>
					<Navbar page='home' />
					<DivPagL>
						<Topbar page='home' onclickfun={() => {}} tornaIndietro={false} />
						<Container>
							<div className={css.divSezioni}>
								<Button
									onClick={() => navigate("/palestra")}
									className='h-[50px] border-stone-800 w-[60%]'
									variant='outline'
								>
									<p className={css.sezioniP}>Palestra</p>
								</Button>
								<Button
									onClick={() => {
										navigate(
											"/organizzazione/" + getFormattedDate(new Date(), "eng")
										);
									}}
									className='h-[50px] border-stone-800 w-[60%]'
									variant='outline'
								>
									<p className={css.sezioniP}>Organizzazione</p>
								</Button>
							</div>
							<div
								style={{
									display: "flex",
									width: "100%",
									justifyContent: "center",
								}}
							>
								<div className={css.divTasks}>
									<p className={css.titleTasks}>
										{taskOggi.num_rows > 0 ? "Da fare:" : ""}
									</p>
									{taskOggi.num_rows > 0 ? (
										<TabellaL
											taskOggi={taskOggi}
											modificaTaskOggi={modificaTaskOggi}
										/>
									) : (
										""
									)}
								</div>
							</div>
						</Container>
					</DivPagL>
				</DivTotL>
			)}
		</>
	);
}
