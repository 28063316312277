import { useLoaderData, useNavigate } from "react-router-dom";
import css from "./creatask.module.css";
import { useRef, useState } from "react";
import { getFormattedDate, larghezzaSchermo, tipoTask } from "../../utils";
import {
	Container,
	DivPagL,
	DivTotL,
	Navbar,
	Topbar,
} from "../../components/Components";
import { Button } from "../../components/shadcn/Button";
import { Toaster } from "../../components/shadcn/Sonner";
import { toast } from "sonner";
import { addTask } from "../../fetch/fetch";

import imgDone from "../../img/done.png";
import imgInProgress from "../../img/inprogress.png";
import imgToDo from "../../img/todo.png";

function Campi({
	nuovaTask,
	setNuovaTask,
	modificaTaskOggi,
	data,
}: {
	nuovaTask: tipoTask;
	setNuovaTask: Function;
	modificaTaskOggi: Function;
	data: string;
}) {
	const navigate = useNavigate();

	const refOra = useRef(null);
	const refGiorno = useRef(null);

	function creaTask() {
		if (
			nuovaTask.categoria !== "" &&
			nuovaTask.titolo !== "" &&
			nuovaTask.stato !== "" &&
			nuovaTask.priorita !== -1
		) {
			addTask(nuovaTask).then((res) => {
				if (nuovaTask.giorno === getFormattedDate(new Date(), "eng")) {
					modificaTaskOggi({ ...nuovaTask, id: res.id }, "add");
				}
				navigate("/organizzazione/" + nuovaTask.giorno);
			});
		} else {
			toast.error("Inserire tutti i campi obbligatori!");
		}
	}

	return (
		<>
			<div className={css.divCampo}>
				<p className={css.titoloSezione}>Titolo: *</p>
				<input
					type='text'
					defaultValue=''
					className={css.inputSezione}
					onChange={(e) => {
						setNuovaTask({ ...nuovaTask, titolo: e.target.value });
					}}
				/>
			</div>
			<div className={css.divCampo}>
				<p className={css.titoloSezione}>Descrizione:</p>
				<textarea
					className={css.inputSezione}
					style={{
						border: "1px solid var(--coloreBordo)",
						resize: "vertical",
					}}
					rows={10}
					onChange={(e) => {
						setNuovaTask({ ...nuovaTask, descrizione: e.target.value });
					}}
					defaultValue=''
				></textarea>
			</div>
			<div className={css.divCampo}>
				<p className={css.titoloSezione}>Categoria: *</p>
				<input
					type='text'
					defaultValue=''
					className={css.inputSezione}
					onChange={(e) => {
						setNuovaTask({ ...nuovaTask, categoria: e.target.value });
					}}
				/>
			</div>
			<div className={css.divCampo}>
				<p className={css.titoloSezione}>Data: *</p>
				<div className='flex flex-col items-start justify-between w-full'>
					<div className='flex flex-row w-full'>
						<Button
							variant='outline'
							style={{ border: "1px solid var(--coloreBordo)" }}
							className='text-xl mr-[10px] w-40'
							onClick={() => {
								//@ts-ignore
								refGiorno.current.value = data;
								setNuovaTask({ ...nuovaTask, giorno: data });
							}}
						>
							{data.split("-")[2] +
								"/" +
								data.split("-")[1] +
								"/" +
								data.split("-")[0]}
						</Button>
						<input
							type='date'
							defaultValue={data}
							ref={refGiorno}
							className={css.inputSezione}
							onChange={(e) => {
								setNuovaTask({ ...nuovaTask, giorno: e.target.value });
							}}
							style={{ width: "100%" }}
						/>
					</div>
					<div className='flex flex-row w-full mt-[10px]'>
						<Button
							variant='outline'
							style={{ border: "1px solid var(--coloreBordo)" }}
							className='text-xl mr-[10px] w-40'
							onClick={() => {
								//@ts-ignore
								refOra.current.value = null;
								setNuovaTask({ ...nuovaTask, ora: null });
							}}
						>
							Tutto il giorno
						</Button>
						<input
							type='time'
							ref={refOra}
							//@ts-ignore
							defaultValue={null}
							className={css.inputSezione}
							onChange={(e) => {
								setNuovaTask({ ...nuovaTask, ora: e.target.value });
							}}
							style={{ width: "100%" }}
						/>
					</div>
				</div>
			</div>
			<div className={css.divCampo}>
				<p className={css.titoloSezione}>Priorità: *</p>
				<div className={css.divTreBtn}>
					<div
						className={css.divBtn}
						style={
							nuovaTask.priorita.toString() === "1"
								? { borderColor: "#ff0" }
								: {}
						}
						onClick={() => setNuovaTask({ ...nuovaTask, priorita: 1 })}
					>
						<p>1</p>
					</div>
					<div
						className={css.divBtn}
						style={
							nuovaTask.priorita.toString() === "2"
								? { borderColor: "orange" }
								: {}
						}
						onClick={() => setNuovaTask({ ...nuovaTask, priorita: 2 })}
					>
						<p>2</p>
					</div>
					<div
						className={css.divBtn}
						style={
							nuovaTask.priorita.toString() === "3"
								? { borderColor: "#f00" }
								: {}
						}
						onClick={() => setNuovaTask({ ...nuovaTask, priorita: 3 })}
					>
						<p>3</p>
					</div>
				</div>
			</div>
			<div className={css.divCampo}>
				<p className={css.titoloSezione}>Stato: *</p>
				<div className={css.divTreBtn}>
					<div
						className={css.divBtn}
						style={nuovaTask.stato === "td" ? { borderColor: "#f00" } : {}}
						onClick={() => setNuovaTask({ ...nuovaTask, stato: "td" })}
					>
						<img
							src={imgToDo}
							alt=''
							className={css.imgToDo}
							style={
								nuovaTask.stato === "td"
									? {
											filter:
												"invert(29%) sepia(91%) saturate(7444%) hue-rotate(357deg) brightness(94%) contrast(123%)",
									  }
									: {}
							}
						/>
					</div>
					<div
						className={css.divBtn}
						style={nuovaTask.stato === "ip" ? { borderColor: "orange" } : {}}
						onClick={() => setNuovaTask({ ...nuovaTask, stato: "ip" })}
					>
						<img
							src={imgInProgress}
							alt=''
							style={
								nuovaTask.stato === "ip"
									? {
											filter:
												"invert(74%) sepia(68%) saturate(397%) hue-rotate(357deg) brightness(93%) contrast(98%)",
									  }
									: {}
							}
							className={css.imgInProgress}
						/>
					</div>
					<div
						className={css.divBtn}
						style={nuovaTask.stato === "d" ? { borderColor: "#0f0" } : {}}
						onClick={() => setNuovaTask({ ...nuovaTask, stato: "d" })}
					>
						<img
							src={imgDone}
							alt=''
							style={
								nuovaTask.stato === "d"
									? {
											filter:
												"invert(50%) sepia(81%) saturate(2088%) hue-rotate(82deg) brightness(121%) contrast(120%)",
									  }
									: {}
							}
							className={css.imgDone}
						/>
					</div>
				</div>
			</div>
			<div
				className={css.divCampo}
				style={{ display: "flex", alignItems: "center" }}
			>
				<Button className='text-xl w-3/4' onClick={creaTask}>
					Crea task
				</Button>
			</div>
		</>
	);
}

function CreaTask({ modificaTaskOggi }: { modificaTaskOggi: Function }) {
	const { data }: any = useLoaderData();
	const navigate = useNavigate();

	const [nuovaTask, setNuovaTask] = useState({
		id: -1,
		categoria: "",
		titolo: "",
		descrizione: "",
		stato: "",
		priorita: -1,
		giorno: data,
		ora: null,
	} as tipoTask);

	return (
		<>
			{larghezzaSchermo < 1024 ? (
				<>
					<Topbar
						page='Crea task'
						tornaIndietro={true}
						onclickfun={() => {
							console.log(data);
							if (data === getFormattedDate(new Date(), "eng")) {
								navigate("/organizzazione/" + data);
							} else {
								navigate("/calendario");
							}
						}}
					/>
					<Container>
						<div className={css.divCampi}>
							<Campi
								modificaTaskOggi={modificaTaskOggi}
								nuovaTask={nuovaTask}
								setNuovaTask={setNuovaTask}
								data={data}
							/>
						</div>
					</Container>
					<Navbar page='organizzazione' />
					<Toaster richColors />
				</>
			) : (
				<DivTotL>
					<Navbar page='organizzazione' />
					<Toaster richColors />
					<DivPagL>
						<Topbar
							page='Crea task'
							tornaIndietro={true}
							onclickfun={() => {
								console.log(data);
								if (data === getFormattedDate(new Date(), "eng")) {
									navigate("/organizzazione/" + data);
								} else {
									navigate("/calendario");
								}
							}}
						/>
						<Container>
							<div className={css.divCampi}>
								<Campi
									modificaTaskOggi={modificaTaskOggi}
									nuovaTask={nuovaTask}
									setNuovaTask={setNuovaTask}
									data={data}
								/>
							</div>
						</Container>
					</DivPagL>
				</DivTotL>
			)}
		</>
	);
}

export default CreaTask;
