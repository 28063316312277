import { useLoaderData, useNavigate } from "react-router-dom";
import { larghezzaSchermo, tipoEsercizio } from "../../utils";
import { useEffect, useRef, useState } from "react";
import { getDay, modifyExercise } from "../../fetch/fetch";
import {
	Container,
	DivPagL,
	DivTotL,
	Navbar,
	Topbar,
} from "../../components/Components";

import css from "./esercizi.module.css";
import "./timer.css";
import { Button } from "../../components/shadcn/Button";

import imgRecupero from "../../img/timer.png";
import imgEdit from "../../img/edit.png";

const BottoneRecupero = ({
	recupero,
	disable,
}: {
	recupero: number;
	disable?: boolean;
}) => {
	var intervalTimer: NodeJS.Timeout;
	function iniziaRecupero() {
		//elementi
		var dd = document.getElementById("dd") || new HTMLElement();
		var timer_dot = document.getElementById("timer_dot") || new HTMLElement();
		var secondiRimanenti =
			document.getElementById("timer") || new HTMLElement();

		//chiudiPopUp
		var esciDalPopUp =
			document.getElementById("esciDalPopUp") || new HTMLElement();
		esciDalPopUp.style.display = "flex";
		esciDalPopUp.addEventListener("click", function () {
			clearInterval(intervalTimer);
			esciDalPopUp.style.display = "none";
			popup.style.display = "none";
		});
		//popup
		var popup = document.getElementById("popUpTimer") || new HTMLElement();
		popup.style.display = "flex";

		var end = new Date();
		end.setSeconds(end.getSeconds() + recupero);

		clearInterval(intervalTimer);
		intervalTimer = setInterval(function () {
			var now = new Date();
			var difference = end.getTime() - now.getTime();

			var min = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
			var sec = Math.floor((difference % (1000 * 60)) / 1000);

			if (min < 0 || sec < 0) {
				clearInterval(intervalTimer);
				esciDalPopUp.style.display = "none";
				popup.style.display = "none";
			}

			var current = min * 60 + sec;
			var percentuale = (100 * current) / recupero;

			dd.style.strokeDashoffset = ((percentuale * 440) / 100).toString();
			timer_dot.style.transform = `rotateZ(${
				360 - current * (360 / recupero)
			}deg)`;

			var minStr: string = min.toString();
			var secStr: string = sec.toString();

			if (sec < 10) {
				secStr = "0" + sec;
			}
			if (min < 10) {
				minStr = "0" + min;
			}
			secondiRimanenti.innerHTML = minStr + ":" + secStr;

			if (sec.toString() === "00" && min.toString() === "00") {
				clearInterval(intervalTimer);
				esciDalPopUp.style.display = "none";
				popup.style.display = "none";
			}
		});
	}
	function finisciInterval() {
		clearInterval(intervalTimer);
	}

	return (
		<>
			{larghezzaSchermo < 1024 ? (
				<>
					<Button
						className={css.brDiv}
						style={
							disable
								? {
										background: "var(--coloreDisabilita)",
										color: "white",
										border: 0,
								  }
								: {}
						}
						variant='outline'
						onClick={() => {
							if (recupero !== 0) {
								if (
									(document.getElementById("popUpTimer") || new HTMLElement())
										.style.display === "none"
								) {
									iniziaRecupero();
								} else {
									finisciInterval();
								}
							}
						}}
					>
						<p className='text-xl'>Recupero</p>
					</Button>
				</>
			) : (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<img
						src={imgRecupero}
						alt=''
						onClick={() => {
							if (recupero !== 0) {
								if (
									(document.getElementById("popUpTimer") || new HTMLElement())
										.style.display === "none"
								) {
									iniziaRecupero();
								} else {
									finisciInterval();
								}
							}
						}}
					/>
				</div>
			)}
			<div
				id='esciDalPopUp'
				style={{ display: "none" }}
				onClick={() => {
					finisciInterval();
				}}
			></div>
			<div id='popUpTimer' style={{ display: "none" }}>
				<div id='time'>
					<div className='circle'>
						<div className='dots timer_dot' id='timer_dot'></div>
						<svg>
							<circle cx='70' cy='70' r='70'></circle>
							<circle cx='70' cy='70' r='70' id='dd'></circle>
						</svg>
						<div id='timer'>0</div>
					</div>
				</div>
			</div>
		</>
	);
};
const ModificaValori = ({
	esercizio,
	disable,
	reloadFun,
	day,
}: {
	esercizio: tipoEsercizio;
	disable?: boolean;
	reloadFun: Function;
	day: string;
}) => {
	const [popup, setPopup] = useState(false);

	const reps = useRef(null);
	const carico = useRef(null);
	const recupero = useRef(null);

	useEffect(() => {
		const popup = document.getElementById("popupModifica");
		popup?.addEventListener("keydown", (e) => {
			if (e.key === "Enter") {
				functionModify(popup.children[0].innerHTML);
			}
		});
		// eslint-disable-next-line
	}, []);

	async function functionModify(id: string) {
		await modifyExercise(
			id,
			//@ts-ignore
			reps.current.value.toString(),
			//@ts-ignore
			carico.current.value.toString(),
			//@ts-ignore
			recupero.current.value.toString()
		);

		await reloadFun();

		setPopup(false);
	}

	return (
		<>
			{larghezzaSchermo < 1024 ? (
				<>
					<Button
						className={css.bmDiv}
						style={
							disable
								? {
										background: "var(--coloreDisabilita)",
										color: "white",
										border: 0,
								  }
								: {}
						}
						variant='outline'
						onClick={() => {
							if (JSON.stringify(esercizio) !== "{}") {
								setPopup(true);
							}
						}}
					>
						<p className='text-xl'>Modifica valori</p>
					</Button>
				</>
			) : (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<img
						src={imgEdit}
						alt=''
						onClick={() => {
							if (JSON.stringify(esercizio) !== "{}") {
								setPopup(true);
							}
						}}
					/>
				</div>
			)}
			<div
				id='esciDalPopUp'
				style={popup ? { display: "flex" } : { display: "none" }}
				onClick={() => setPopup(false)}
			></div>
			<div
				className={css.popupModifica}
				style={popup ? { display: "flex" } : { display: "none" }}
				id='popupModifica'
			>
				<div id='id' style={{ display: "none" }}>
					{esercizio.id}
				</div>
				<div className={`${css.divModifica} ${css.modTitolo}`}>
					{esercizio.nome}
				</div>
				<div className={css.divModifica} style={{ marginTop: "16px" }}>
					<div style={{ width: "100%" }}>
						<label className={css.modLabel} htmlFor='inputReps'>
							Reps
						</label>
					</div>
					<input
						type='text'
						defaultValue={esercizio.reps}
						className={css.modInput}
						id='inputReps'
						ref={reps}
					/>
				</div>
				<div className={css.divModifica} style={{ marginTop: "16px" }}>
					<div style={{ width: "100%" }}>
						<label className={css.modLabel} htmlFor='inputCarico'>
							Carico
						</label>
					</div>
					<input
						type='text'
						defaultValue={esercizio.carico}
						className={css.modInput}
						id='inputCarico'
						ref={carico}
					/>
				</div>
				<div className={css.divModifica} style={{ marginTop: "16px" }}>
					<div style={{ width: "100%" }}>
						<label className={css.modLabel} htmlFor='inputRecupero'>
							Recupero
						</label>
					</div>
					<input
						type='text'
						defaultValue={esercizio.recupero}
						className={css.modInput}
						id='inputRecupero'
						ref={recupero}
					/>
				</div>
				<div className={css.divModifica} style={{ marginTop: "16px" }}>
					<button
						className={css.modSubmit}
						onClick={() => functionModify(esercizio.id.toString())}
					>
						Modifica
					</button>
				</div>
			</div>
		</>
	);
};

const ElementoLista = ({
	esercizio,
	onclickfun,
	esercizi,
	giorno,
	setEsercizi,
}: {
	esercizio: tipoEsercizio;
	onclickfun: Function;
	esercizi: Array<tipoEsercizio>;
	giorno: any;
	setEsercizi: Function;
}) => {
	function returnExercise(id: string) {
		let esercizio: tipoEsercizio = {} as tipoEsercizio;
		esercizi.forEach((item) => {
			if (item.id === id) {
				esercizio = item;
			}
		});

		return esercizio;
	}

	return (
		<>
			<tr
				onClick={() => onclickfun(parseInt(esercizio.recupero), esercizio.id)}
				id={esercizio.id}
				className='h-[60px]'
			>
				<td>
					<p>{esercizio.nome}</p>
				</td>
				<td>{esercizio.reps}</td>
				<td>{esercizio.carico}</td>
				{larghezzaSchermo >= 1024 ? (
					<>
						<td>
							<BottoneRecupero recupero={parseInt(esercizio.recupero)} />
						</td>
						<td>
							<ModificaValori
								esercizio={returnExercise(esercizio.id)}
								day={giorno}
								reloadFun={async (): Promise<any> => {
									await getDay(giorno).then((data) => {
										setEsercizi(data);
									});
								}}
							/>
						</td>
					</>
				) : (
					""
				)}
			</tr>
		</>
	);
};

export function Giorni() {
	const data: any = useLoaderData();
	const navigate = useNavigate();
	const [esercizi, setEsercizi] = useState([] as Array<tipoEsercizio>);
	const [elemento, setElemento] = useState({ id: "-1", recupero: 0 });

	if (!data) return <p>CARICAMENTO</p>;

	if (esercizi.length === 0) {
		getDay(data.day).then((data) => {
			setEsercizi(data);
		});
		return <p>CARICAMENTO</p>;
	}

	function createList() {
		return esercizi.map((item, index) => (
			<ElementoLista
				esercizio={item}
				key={index}
				onclickfun={elementoCliccato}
				esercizi={esercizi}
				giorno={data.day}
				setEsercizi={setEsercizi}
			/>
		));
	}

	function elementoCliccato(recupero: number, id: string) {
		if (larghezzaSchermo < 1024) {
			setElemento({ id: id, recupero: recupero });

			const elementoCliccato = document.getElementById(id) || new HTMLElement();
			if (elementoCliccato.style.backgroundColor === "lightgray") {
				elementoCliccato.style.backgroundColor = "";
				setElemento({ id: "-1", recupero: 0 });
			} else {
				const elementi =
					document
						.getElementById("sezioneEsercizi")
						?.getElementsByTagName("tr") || new Array<HTMLDivElement>();

				for (let i = 0; i < elementi.length; i++) {
					elementi[i].style.background = "";
				}

				elementoCliccato.style.backgroundColor = "lightgray";
			}
		}
	}

	function returnExercise(id: string) {
		let esercizio: tipoEsercizio = {} as tipoEsercizio;
		esercizi.forEach((item) => {
			if (item.id === id) {
				esercizio = item;
			}
		});

		return esercizio;
	}

	return (
		<>
			{esercizi ? (
				larghezzaSchermo < 1024 ? (
					<>
						<Topbar
							page={data.day}
							onclickfun={() => navigate("/palestra")}
							tornaIndietro={true}
						/>
						<Container>
							<div id='lista' className={css.divLista}>
								<table className={css.tabellaEsercizi}>
									<thead>
										<tr>
											<th>Esercizio</th>
											<th style={{ width: "20%" }}>Reps</th>
											<th style={{ width: "20%" }}>Carico</th>
										</tr>
									</thead>
									<tbody id='sezioneEsercizi'>{createList()}</tbody>
								</table>
							</div>
						</Container>
						<div className={css.barraTrasparente}>
							<BottoneRecupero
								recupero={elemento.recupero}
								disable={elemento.recupero === 0 ? true : false}
							/>
							<ModificaValori
								esercizio={returnExercise(elemento.id)}
								disable={elemento.id === "-1" ? true : false}
								day={data.day}
								reloadFun={async (): Promise<any> => {
									await getDay(data.day).then((data) => {
										setEsercizi(data);
									});
								}}
							/>
						</div>
						<Navbar page='palestra' />
					</>
				) : (
					<DivTotL>
						<Navbar page='palestra' />
						<DivPagL>
							<Topbar
								page={data.day}
								onclickfun={() => navigate("/palestra")}
								tornaIndietro={true}
							/>
							<Container>
								<div id='lista' className={css.divLista}>
									<table className={css.tabellaEsercizi}>
										<thead>
											<tr>
												<th>Esercizio</th>
												<th style={{ width: "15%" }}>Reps</th>
												<th style={{ width: "15%" }}>Carico</th>
												<th style={{ width: "12%" }}>Recupero</th>
												<th style={{ width: "12%" }}>Modifica</th>
											</tr>
										</thead>
										<tbody id='sezioneEsercizi'>{createList()}</tbody>
									</table>
								</div>
							</Container>
						</DivPagL>
					</DivTotL>
				)
			) : (
				""
			)}
		</>
	);
}
