import { useState } from "react";
import { Calendar } from "../../components/shadcn/Calendar";
import {
	Container,
	DivPagL,
	DivTotL,
	Navbar,
	Topbar,
} from "../../components/Components";
import css from "./calendario.module.css";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/shadcn/Button";
import { getFormattedDate, giorni, larghezzaSchermo } from "../../utils";
import { giorniConTask } from "../../fetch/fetch";
import { addMonths } from "date-fns";

function Calendario() {
	const [data, setData] = useState<Date | undefined>(new Date());
	const [month, setMonth] = useState<Date>(new Date());
	const navigate = useNavigate();

	const [giorni, setGiorni] = useState({} as giorni);
	const [chiestiGiorni, setChiestiGiorni] = useState(false);

	if (Object.keys(giorni).length === 0 && !chiestiGiorni) {
		setChiestiGiorni(true);
		impostaGiorni(
			getFormattedDate(new Date(), "eng").split("-")[0],
			getFormattedDate(new Date(), "eng").split("-")[1]
		);
	}

	async function impostaGiorni(anno: string, mese: string) {
		giorniConTask(anno, mese).then((res) => {
			setGiorni(res);
			setChiestiGiorni(false);
		});
	}

	if (Object.keys(giorni).length !== 0) {
		return (
			<>
				{larghezzaSchermo < 1024 ? (
					<>
						<Topbar
							page='calendario'
							onclickfun={() => {}}
							tornaIndietro={false}
						/>
						<Container>
							<div className='h-full flex justify-center items-center flex-col'>
								<div
									style={{
										display: "flex",
										justifyContent: "center",
										width: "100%",
										marginTop: "17px",
									}}
								>
									<div className={css.divData}>
										<p className='text-[23px] font-[500]'>
											{getFormattedDate(data || new Date(), "ita")}
										</p>
									</div>
								</div>
								<div className={css.divCalendario}>
									<Calendar
										mode='single'
										selected={data}
										onSelect={setData}
										giorni={giorni}
										className='rounded-md border border-stone-800 border-y-0 rounded-b-none rounded-t-none pb-0'
										month={month}
										onMonthChange={(props: any) => {
											impostaGiorni(
												getFormattedDate(props, "eng").split("-")[0],
												getFormattedDate(props, "eng").split("-")[1]
											).then(() => setMonth(props));
										}}
									/>
								</div>
								<div
									style={{
										display: "flex",
										justifyContent: "center",
										width: "100%",
									}}
								>
									<div className={css.divAzioni}>
										<div className={css.divBottoniAzioni}>
											<Button
												onClick={() => {
													const parametro = getFormattedDate(
														data || new Date(),
														"eng"
													);

													navigate("/organizzazione/" + parametro);
												}}
												className='h-[45px] border-stone-400 w-[45%]'
												variant='outline'
											>
												<p className={css.divBottoniAzioniP}>
													Visualizza tasks
												</p>
											</Button>
											<Button
												onClick={() => {
													navigate(
														"/organizzazione/createtask/" +
															getFormattedDate(data || new Date(), "eng")
													);
												}}
												className='h-[45px] border-stone-400 w-[45%]'
												variant='outline'
											>
												<p className={css.divBottoniAzioniP}>Aggiungi task</p>
											</Button>
										</div>
										<Button
											variant='outline'
											className='text-lg w-[45%] h-[45px] border-stone-400'
											onClick={() => {
												impostaGiorni(
													getFormattedDate(new Date(), "eng").split("-")[0],
													getFormattedDate(new Date(), "eng").split("-")[1]
												).then(() => {
													setData(new Date());
													setMonth(new Date());
												});
											}}
										>
											Oggi
										</Button>
									</div>
								</div>
							</div>
						</Container>
						<Navbar page='calendario' />
					</>
				) : (
					<DivTotL>
						<Navbar page='calendario' />
						<DivPagL>
							<Topbar
								page='calendario'
								onclickfun={() => {}}
								tornaIndietro={false}
							/>
							<Container>
								<div className='h-full flex justify-center items-center flex-col'>
									<div
										style={{
											display: "flex",
											justifyContent: "center",
											width: "100%",
											marginTop: "17px",
										}}
									>
										<div className={css.divData}>
											<p className='text-[23px] font-[500]'>
												{getFormattedDate(data || new Date(), "ita")}
											</p>
										</div>
									</div>
									<div className={css.divCalendario}>
										<Calendar
											mode='single'
											selected={data}
											onSelect={setData}
											giorni={giorni}
											className='rounded-md border border-stone-800 border-y-0 rounded-b-none rounded-t-none pb-0'
											month={month}
											onMonthChange={(props: any) => {
												impostaGiorni(
													getFormattedDate(props, "eng").split("-")[0],
													getFormattedDate(props, "eng").split("-")[1]
												).then(() => setMonth(props));
											}}
										/>
									</div>
									<div
										style={{
											display: "flex",
											justifyContent: "center",
											width: "100%",
										}}
									>
										<div className={css.divAzioni}>
											<div className={css.divBottoniAzioni}>
												<Button
													onClick={() => {
														const parametro = getFormattedDate(
															data || new Date(),
															"eng"
														);

														navigate("/organizzazione/" + parametro);
													}}
													className='h-[45px] border-stone-400 w-[45%]'
													variant='outline'
												>
													<p className={css.divBottoniAzioniP}>
														Visualizza tasks
													</p>
												</Button>
												<Button
													onClick={() => {
														navigate(
															"/organizzazione/createtask/" +
																getFormattedDate(data || new Date(), "eng")
														);
													}}
													className='h-[45px] border-stone-400 w-[45%]'
													variant='outline'
												>
													<p className={css.divBottoniAzioniP}>Aggiungi task</p>
												</Button>
											</div>
											<Button
												variant='outline'
												className='text-lg w-[45%] h-[45px] border-stone-400'
												onClick={() => {
													impostaGiorni(
														getFormattedDate(new Date(), "eng").split("-")[0],
														getFormattedDate(new Date(), "eng").split("-")[1]
													).then(() => {
														setData(new Date());
														setMonth(new Date());
													});
												}}
											>
												Oggi
											</Button>
										</div>
									</div>
								</div>
							</Container>
						</DivPagL>
					</DivTotL>
				)}
			</>
		);
	}
	return <p>CARICAMENTO</p>;
}

export default Calendario;
