import { useNavigate } from "react-router-dom";
import { getFormattedDate, larghezzaSchermo } from "../utils";

import css from "./components.module.css";
import { MouseEventHandler } from "react";

import imgHome from "./imgNavbar/homepage.png";
import imgDumbell from "./imgNavbar/dumbell.png";
import imgOrganizzazione from "./imgNavbar/organizzazione.png";
import imgCalendar from "./imgNavbar/calendar.png";
import imgGoBack from "./imgNavbar/goback.png";

export const Topbar = ({
	page,
	onclickfun,
	tornaIndietro,
}: {
	page: string;
	tornaIndietro: boolean;
	onclickfun: MouseEventHandler;
}) => {
	return (
		<div className={larghezzaSchermo < 1024 ? css.divTopbar : css.divTopbarL}>
			<p className={css.titoloTopbar}>{page}</p>
			{tornaIndietro ? (
				<img
					src={imgGoBack}
					alt=''
					className={css.gobackTopbar}
					onClick={onclickfun}
				/>
			) : (
				""
			)}
		</div>
	);
};

export const Container = ({ children }: { children: React.ReactNode }) => {
	return (
		<div
			className={larghezzaSchermo < 1024 ? css.divContainer : css.divContainerL}
		>
			{children}
		</div>
	);
};

export const Navbar = ({ page }: { page: string }) => {
	const navigate = useNavigate();

	return (
		<div className={larghezzaSchermo < 1024 ? css.divNavbar : css.divNavbarL}>
			<div onClick={() => navigate("/")}>
				<img
					src={imgHome}
					alt=''
					className={page === "home" ? css.paginaSelezionata : css.imgNavbar}
				/>
			</div>
			<div onClick={() => navigate("/palestra")}>
				<img
					src={imgDumbell}
					alt=''
					className={
						page === "palestra" ? css.paginaSelezionata : css.imgNavbar
					}
				/>
			</div>
			<div
				onClick={() =>
					navigate("/organizzazione/" + getFormattedDate(new Date(), "eng"))
				}
			>
				<img
					src={imgOrganizzazione}
					alt=''
					className={
						page === "organizzazione" ? css.paginaSelezionata : css.imgNavbar
					}
					style={{ width: "36px", height: "36px" }}
				/>
			</div>
			<div onClick={() => navigate("/calendario")}>
				<img
					src={imgCalendar}
					alt=''
					className={
						page === "calendario" ? css.paginaSelezionata : css.imgNavbar
					}
				/>
			</div>
		</div>
	);
};

//div per la visione pc
export const DivTotL = ({ children }: { children: React.ReactNode }) => {
	return <div className='flex flex-row h-screen w-screen'>{children}</div>;
};
export const DivPagL = ({ children }: { children: React.ReactNode }) => {
	return <div className='flex flex-col w-full h-full'>{children}</div>;
};
