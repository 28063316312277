import { useNavigate } from "react-router-dom";
import {
	Topbar,
	Container,
	Navbar,
	DivTotL,
	DivPagL,
} from "../../components/Components";

import css from "./palestra.module.css";
import { Button } from "../../components/shadcn/Button";
import { larghezzaSchermo } from "../../utils";

const SplitButtons = ({ split }: { split: Array<string> }) => {
	const navigate = useNavigate();

	return (
		<>
			{split.map((item, index) => (
				<Button
					onClick={() => navigate("/palestra/" + item)}
					key={index}
					className='h-[50px] border-stone-800 w-[60%]'
					variant='outline'
				>
					<p className={css.splitButtonP}>{item}</p>
				</Button>
			))}
		</>
	);
};

export function Palestra() {
	const navigate = useNavigate();

	return (
		<>
			{larghezzaSchermo < 1024 ? (
				<>
					<Topbar
						page='palestra'
						onclickfun={() => navigate("/")}
						tornaIndietro={false}
					/>
					<Container>
						<div className={css.divSplitButtons}>
							<SplitButtons split={["push", "pull", "legs"]} />
						</div>
					</Container>
					<Navbar page='palestra' />
				</>
			) : (
				<DivTotL>
					<Navbar page='palestra' />
					<DivPagL>
						<Topbar
							page='palestra'
							onclickfun={() => navigate("/")}
							tornaIndietro={false}
						/>
						<Container>
							<div className={css.divSplitButtons}>
								<SplitButtons split={["push", "pull", "legs"]} />
							</div>
						</Container>
					</DivPagL>
				</DivTotL>
			)}
		</>
	);
}
